import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Client } from './client.service';
import { Observable } from 'rxjs';
import { AppNotification } from '../shared/components/notification-center/notification-types';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private socket: Socket;
  readonly WEBSOCKET_URL = window.location.host.indexOf('localhost') > -1 ? environment.webSocketUrl: window.location.protocol + '//' + window.location.host;

  constructor(private client: Client) { 
    var user_data = JSON.parse(localStorage.getItem('user_data'))
    this.socket = io(this.WEBSOCKET_URL,{
      path : '/notifications',
      extraHeaders: {
        location : user_data.location
      }
    });
  }

  private readonly route = `notifications`;

    listenToNotifications(): any {
      return new Observable(observer => {
        this.socket.on('notifications',(msg) => {
          observer.next(msg);
        });
        return () => {
          this.socket.disconnect();
        }
      });
    }

    updateNotification (notification:AppNotification): Observable<Notification> {            
      return <Observable<Notification>> this.client.put(this.route, notification);     
    }

    getNotifications(): Observable<{notifications:any[]}> {      
      return <Observable<{notifications:any[]}>>this.client.get(this.route);
    }

}
