/*    
  @author : Tharindu Premasiri
  @copyright: 2021 Beyondone.co 
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NotificationService } from './../../../service/notification.service';
import { NOTIFICATION_CONFIG } from './notification-config';
import { NotificationListItem, AppNotification } from './notification-types';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
  
  DISPLAY_MAX_COUNT = 99;
  items = NOTIFICATION_CONFIG;

  list : NotificationListItem[] = [];
  totalCount:string;

  @ViewChild('op') overlayPanel:OverlayPanel;
  userdata: any;

  constructor(
    private router: Router,
    private titleService:Title,
    private notificationService:NotificationService,
  ) {}

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('user_data'));
    if(this.userdata.facNo != '0'){
      this.getNotifications();
      this.listenToNotifications();
    }
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.overlayPanel.hide();        
      }
    });
  }

  private listenToNotifications() {
    this.notificationService.listenToNotifications()
      .subscribe((notification: AppNotification) => {
        console.log('New Notifications', notification);
        if(this.filterByPermission(notification.channel)){
          this.list.push({...NOTIFICATION_CONFIG[notification.channel],...notification});
          //this.items[notification.channel].messages.push(notification);
          this.items[notification.channel].count += 1;
          var count = this.calTotalCount();
          this.updateTitle(count);
        }
      });
  }

  private updateTitle(count: number) {
    let title = this.titleService.getTitle();
    if (count > 0 ){
      if(/\([\d]+\)/.test(title)) title = title.split(') ')[1];
      this.titleService.setTitle(`(${count}) ${title}`);
    }
    if(count == 0){
      this.titleService.setTitle(title.split(') ')[1] || title);
    }
      
  }

  private getNotifications() {
    this.notificationService.getNotifications()
    .subscribe(res => {
      if(res.notifications && res.notifications.length > 0){
        this.list = res.notifications.filter(e => this.filterByPermission(e.channel))
        .map(e => ({...NOTIFICATION_CONFIG[e.channel],...e}));
       
        //group notifications
        this.list.forEach(e => this.items[e.channel].count += 1);
        var count = this.calTotalCount();
        this.updateTitle(count);
      }
    }, err => {
      console.log(err);
    });
  }

  async itemAction(item:NotificationListItem | any){
    await this.router.navigate([item.link],{queryParams : item.queryParams});
    this.clearItem(item);
  }

  clearItem(item: NotificationListItem | any) {
    this.list = this.list.filter(e => e._id != item._id);
    var count = this.calTotalCount();
    this.updateTitle(count);
    this.updateNotification({...item,readBy:[...item.readBy,this.userdata.username]})
  }

  async groupedItemAction(item){
    await this.router.navigate([item.link],{queryParams : item.queryParams});
    this.clearGroup(item.channel)
  }

  clearGroup(channel){
    var list = this.list.filter(e => e.channel == channel);
    this.items[channel].count = 0;
    for (const _item of list) {
     this.clearItem(_item); 
    }
  }

  updateNotification(notification:AppNotification){
    this.notificationService.updateNotification(notification)
        .subscribe( res => {
          //
        },err => {
          console.log('err',err)
        })
  }

  private calTotalCount(){
    var count = (this.list).length;
    this.totalCount = count > this.DISPLAY_MAX_COUNT ? `${this.DISPLAY_MAX_COUNT}+` : count.toString();
    return count;
  }

  markAllRead(){
    this.list = this.list.map(e =>({...e,read:true}))
    this.calTotalCount();
  } 
  
  getGroupedN(){
    var grouped = Object.entries(this.items).map(([key,value]) => ({...value,channel:key}));
    return grouped.filter(e => e.count > 0);
  }

  filterByPermission(channel):boolean{
    if (this.userdata.roles && this.userdata.roles.permissions) {
      let permissions: any[] = this.userdata.roles.permissions;
      if (this.userdata.roles.parent) {
        permissions = permissions.concat(this.userdata.roles.parent.permissions);
      }
      if (permissions.map((e) => e.module).includes(NOTIFICATION_CONFIG[channel].link.substr(1))) {
        return true
      }return false;
    }
  }

  trackByFn(index: number, item): number {
    return item.channel;
  }
}

