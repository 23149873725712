import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';

@Injectable()
export class IsLoggedInGuardService implements CanActivate {

  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const user = JSON.parse(localStorage.getItem('user_data'));

    if (user == null) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
