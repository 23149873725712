export interface NavItems{
  name:string;
  url:string;
  icon:string;
  children?:NavItems[];
  roles?: string[];
}

export const navItems:NavItems[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer',
  //   roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER']
  // },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'fas fa-cog',
    roles: ['SUPER ADMIN'],
    children: [
      {
        name: 'Companies',
        url: '/factorys',
        icon: 'fas fa-building',
        roles: ['SUPER ADMIN']
      },
      {
        name: 'Roles & Permissions',
        url: '/roles',
        icon: 'fas fa-lock',
        roles: ['SUPER ADMIN']
      },
      {
        name: 'Users',
        url: '/users',
        icon: 'fas fa-users',
        roles: ['ADMIN', 'SUPER ADMIN', 'MASTER_R','MASTER_W']
      },
      {
        name: 'Configurations',
        url: '/config',
        icon: 'fas fa-tools',
        roles: ['ADMIN', 'SUPER ADMIN', 'MASTER_R','MASTER_W']
      },
    ]
  },
  {
    name: 'Master',
    url: '/master',
    icon: 'icon-equalizer',
    roles:['ADMIN', 'SUPER ADMIN', 'EUSER', 'IUSER', 'MASTER_R','MASTER_W'],
    children: [
      {
        name: 'UoM',
        url: '/uoms',
        icon: 'fas fa-balance-scale',
        roles: ['ADMIN', 'USER']
      },     
       {
         name: 'Employees',
         url: '/employees',
         icon: 'fas fa-user-tie',
         roles: ['ADMIN', 'SUPER ADMIN',  'USER', 'EUSER', 'IUSER', 'MASTER_R','MASTER_W']
       },
      {
        name: 'Customers',
        url: '/customers',
        icon: 'fas fa-address-card',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'MASTER_R','MASTER_W']
      },
      {
        name: 'Suppliers',
        url: '/suppliers',
        icon: 'fas fa-truck',
        roles: ['SUPER ADMIN', 'IUSER', 'MASTER_R','MASTER_W']
      },
      {
        name: 'Item Inventory',
        url: '/iteminventorys',
        icon: 'fas fa-warehouse',
        roles: ['SUPER ADMIN', 'IUSER', 'MASTER_R','MASTER_W']
      },
      {
        name: 'Vehicle',
        url: '/vehicle',
        icon: 'fas fa-car',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER',]
      },
      {
        name: 'Locations',
        url: '/locations',
        icon: 'fas fa-map-marker-alt',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'MASTER_R','MASTER_W']
      },
      
      // {
      //   name: 'Category',
      //   url: '/categorys',
      //   icon: 'icon-user',
      //   roles: ['ADMIN', 'SUPER ADMIN']
      // },
      {
        name: 'Key Values',
        url: '/keyvalues',
        icon: 'fas fa-folder-open',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER']
      },
    ]
  },
  {
    name: 'Transaction',
    url: '/tx',
    icon: 'fas fa-exchange-alt',
    roles: ['ADMIN', 'SUPER ADMIN',  'EUSER', 'IUSER', 'TX_R','TX_W'],
    children: [      
      {
        name: 'Order Request',
        url: '/orderrequest',
        icon: 'cil-library-add',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'TX_R','TX_W']
      },
      {
        name: 'Purchase Order',
        url: '/purchaseorders',
        icon: 'cil-boat-alt',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'TX_R','TX_W']
      },
     /* {
        name: 'Sales',
        url: '/saless',
        icon: 'icon-user',
        roles: ['USER', 'ADMIN']
      },*/
      {
        name: 'Item Issuing',
        url: '/issue',
        icon: 'fas fa-dolly-flatbed',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'TX_R','TX_W']
      },
      {
        name: 'Return/Transfer',
        url: '/return-transfer',
        icon: 'fas fa-refresh',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'TX_R','TX_W']
      }

     ]
  },
  {
    name: 'Vehicle OR',
    url: '/tx',
    icon: 'fas fa-truck',
    children: [      
      {
        name: 'VOR Request',
        url: '/vehicleorderrequest',
        icon: 'fas fa-truck',
      },
      {
        name: 'VOR Item Issuing',
        url: '/vor/issuing',
        icon: 'fas fa-dolly-flatbed',
      },
     ]
  },
  {
    name: 'Approvals',
    url: '/approval',
    icon: 'fas fa-clipboard-check',
    roles: ['ADMIN', 'SUPER ADMIN',  'EUSER', 'IUSER', 'SET_R', 'SET_W'],
    children: [      
      {
        name: 'OR Approval',
        url: '/orapproval',
        icon: 'fas fa-clipboard-check',
        roles: ['ADMIN', 'SUPER ADMIN', 'USER', 'EUSER', 'IUSER', 'SET_R', 'SET_W']
      },
      {
        name: 'VOR Approval',
        url: '/vor/approval',
        icon: 'fas fa-clipboard-check',
      },
      {
        name: 'EQR Approval',
        url: '/equipments/request/approval',
        icon: 'fas fa-clipboard-check',
      }
     ]
  },
  {
    name: 'Equipment',
    url: '/app',
    icon: 'fas fa-tractor',
    roles: ['ADMIN', 'SUPER ADMIN','USER'],
    children: [      
      {
        name: 'Equipment Inventory',
        url: '/equipment-inventory',
        icon: 'fas fa-warehouse',
      },
      {
        name: 'Equipment Request',
        url: '/equipments/request',
        icon: 'fas fa-truck',
      },
      {
        name: 'Equip Assignment',
        url: '/request-assignments',
        icon: 'fas fa-clipboard-check',
      },
      {
        name: 'Assignment List',
        url: '/assignments',
        icon: 'fas fa-tasks',
      },
      {
        name: 'Transfer',
        url: '/equipment-transfers',
        icon: 'fas fa-refresh',
      }
      
     ]
  },
  {
    name: 'Document',
    url: '/documents',
    icon: 'fas fa-file',
  }, 
  {
    name: 'Reports',
    url: '/reports',
    icon: 'fas fa-file-invoice',
    children: [
      {
        name: 'OR Report',
        url: '/or-report',
        icon: 'cil-library-add',
      },
      {
        name: 'PO Report',
        url: '/po-report',
        icon: 'cil-boat-alt',
      },
      {
        name: 'VOR Report',
        url: '/vor-report',
        icon: 'cil-library-add',
      },
      {
        name: 'VOR Issuing Report',
        url: '/vor/vor-issuing-report',
        icon: 'fas fa-dolly-flatbed',
      },
      {
        name: 'Item Issuing Report',
        url: '/item-report',
        icon: 'fas fa-dolly-flatbed',
      },
      {
        name: 'Assignment Report',
        url: '/assignment-report',
        icon: 'fas fa-tasks',
      },
      {
        name: 'Site Budget Report',
        url: '/limits-report',
        icon: 'fas fa-file-invoice-dollar',
      }
     ]
  },

];
