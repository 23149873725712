import { HasMenuPermissionGuardService } from './service/route-guards/is-menu-perm-present.guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// import { P404Component } from './views/error/404.component';
// import { P500Component } from './views/error/500.component';
// import { LandingComponent } from './views/landing/landing.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
// import { ChartsModule } from 'ng2-charts/ng2-charts';
import { IsLoggedInGuardService } from './service/route-guards/is-logged-in.guard.service';
import { IsLoggedOutGuardService } from './service/route-guards/is-logged-out.guard.service';
import { IsRolePresentGuardService } from './service/route-guards/is-role-present.guard.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TagInputModule } from 'ngx-chips';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotificationCenterModule } from './shared/components/notification-center/notification-center.module';
import { MessageService } from 'primeng/api';
import { LoginModule } from './views/login/login.module';
import {ToastModule} from 'primeng/toast';
import { ReactiveFormsModule } from '@angular/forms';


export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AppAsideModule,
    ModalModule.forRoot(),
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToastModule,
    // ChartsModule,
    HttpClientModule,
    FilterPipeModule, 
    BrowserAnimationsModule,
    LoginModule,
    TagInputModule,
    NotificationCenterModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
   
   
 
    
  ],
  providers: [{
    provide: LocationStrategy,//[{provide: APP_BASE_HREF, useValue: '/ifsx'}],
    useClass: HashLocationStrategy
  },
  { provide: DEFAULT_CURRENCY_CODE, 
    useValue: '' 
  },
  IsLoggedInGuardService, 
  IsLoggedOutGuardService, 
  IsRolePresentGuardService,
  HasMenuPermissionGuardService,
  MessageService,
  ],
  bootstrap: [ AppComponent ],  
})
export class AppModule { }
