import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { IsLoggedOutGuardService } from './service/route-guards/is-logged-out.guard.service';
import { IsLoggedInGuardService } from './service/route-guards/is-logged-in.guard.service';
// import { IsRolePresentGuardService } from './service/route-guards/is-role-present.guard.service';
import { HasMenuPermissionGuardService } from './service/route-guards/is-menu-perm-present.guard.service';
// import { LandingComponent } from './views/landing/landing.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [IsLoggedOutGuardService],
    data: {
      title: 'Login'
    }
  },
  // {
  //   path: 'landing',
  //   component: LandingComponent,
  //   data: {
  //     title: 'Landing Page'
  //   }
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [IsLoggedInGuardService],
    data: {
      title: 'Dashboard'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
          roles: ['ADMIN', 'SUPER ADMIN', 'USER']
        },
      },
      {
        path: 'employees',
        loadChildren: () => import('./views/employees/employees.module').then(m => m.EmployeesModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
          roles: ['ADMIN',  'USER']
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
          roles: ['ADMIN', 'SUPER ADMIN', 'USER']
        },
      },
      {
        path: 'factorys',
        loadChildren: () => import('./views/factories/factories.module').then(m => m.FactoriesModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
          roles: ['SUPER ADMIN']
        },
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        // canActivate: [IsRolePresentGuardService],
        data: {
          roles: ['SUPER ADMIN', 'ADMIN', 'USER']
        }
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
          roles: ['ADMIN',  'USER']
        },
      },
      {
        path: 'uoms',
        loadChildren: () => import('./views/uoms/uoms.module').then(m => m.UomsModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./views/suppliers/suppliers.module').then(m => m.SuppliersModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'iteminventorys',
        loadChildren: () => import('./views/iteminventory/iteminventory.module').then(m => m.ItemInventorysModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'locations',
        loadChildren: () => import('./views/locations/locations.module').then(m => m.LocationsModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['SUPER ADMIN','ADMIN',  'USER']
        },
      },
       {
        path: 'purchaseorders',
        loadChildren: () => import('./views/purchaseorders/purchaseorders.module').then(m => m.PurchaseOrdersModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN']
        },
      },
      {
        path: 'orderrequest',
        loadChildren: () => import('./views/orderrequest/orderrequest.module').then(m => m.OrderRequestModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'orapproval',
        loadChildren: () => import('./views/orapproval/orapproval.module').then(m => m.OrApprovalModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'issue',
        loadChildren: () => import('./views/issuing/issuing.module').then(m => m.IssuingModule),

        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN']
        },
      },
      {
        path: 'return-transfer',
        loadChildren: () => import('./views/return-transfer/return-transfer.mudule').then(m => m.ReturnTransferModule),

        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN']
        },
      },
      {
        path: 'keyvalues',
        loadChildren: () => import('./views/keyvalues/keyvalues.module').then(m => m.KeyValuesModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'SUPER ADMIN']
        },
      },
      {
        path: 'roles',
        loadChildren: () => import('./views/role-permissions/user-roles.module').then(m => m.UserRolesModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'SUPER ADMIN']
        },
      },
      {
        path: 'equipment-inventory',
        loadChildren: () => import('./views/equipment/equipment.module').then(m => m.EquipmentModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'vehicle',
        loadChildren: () => import('./views/vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'assignments',
        loadChildren: () => import('./views/equipment-assignment/equipment-assignment.module').then(m => m.EquipmentAssignmentModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'equipment-transfers',
        loadChildren: () => import('./views/equipment-transfer/equipment-transfer.module').then(m => m.EquipmentTransferModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'documents',
        loadChildren: () => import('./views/document/document.module').then(m => m.DocumentModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'config',
        loadChildren: () => import('./views/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'or-report',
        loadChildren: () => import('./views/or-report/or-report.module').then(m => m.OrReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'vor-report',
        loadChildren: () => import('./views/vor-report/vor-report.module').then(m => m.OrReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'po-report',
        loadChildren: () => import('./views/po-report/po-report.module').then(m => m.PoReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        }
      },
      {
        path: 'item-report',
        loadChildren: () => import('./views/item-report/item-report.module').then(m => m.ItemReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'assignment-report',
        loadChildren: () => import('./views/assignment-report/assignment-report.module').then(m => m.AssignmentReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN','SUPER ADMIN']
        },
      },
      {
        path: 'limits-report',
        loadChildren: () => import('./views/limitsreport/limits-report.module').then(m => m.LimitsReportModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['USER', 'ADMIN']
        }
      },
      {
        path: 'vehicleorderrequest',
        loadChildren: () => import('./views/vehicleorderrequest/vehicleorderrequest.module').then(m => m.VehicleOrderRequestModule),
        canActivate: [HasMenuPermissionGuardService],
        data: {
            roles: ['ADMIN', 'USER']
        },
      },
      {
        path: 'vor/approval',
        loadChildren: () => import('./views/vor-approval/vor-approval.module').then(m => m.VorApprovalModule),
        canActivate: [HasMenuPermissionGuardService],
      },
      {
        path: 'vor/issuing',
        loadChildren: () => import('./views/vor-issuing/vor-issuing.module').then(m => m.VorIssuingModule),
        canActivate: [HasMenuPermissionGuardService],
      },
      {
        path: 'vor/vor-issuing-report',
        loadChildren: () => import('./views//vor-issuing-report/vor-issuing-report.module').then(m => m.VorIssuingReportModule),
        canActivate: [HasMenuPermissionGuardService],
      },
      {
        path: 'equipments/request',
        loadChildren: () => import('./views/equipment-request/equipment-request.module').then(m => m.EquipmentRequestModule),
        canActivate: [HasMenuPermissionGuardService],
      },
      {
        path: 'equipments/request/approval',
        loadChildren: () => import('./views/equipment-request-approval/equipment-request-approval.module').then(m => m.EquipmentRequestApprovalModule),
        canActivate: [HasMenuPermissionGuardService],
      },
      {
        path:'request-assignments',
        loadChildren: () => import('./views/equipment-request-assignments/equipment-request-assignments.module').then(m => m.EquipmentRequestAssignmentsModule),
        canActivate: [HasMenuPermissionGuardService],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
