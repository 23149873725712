<p-toast key="global" position="top-right" [baseZIndex]="1100"></p-toast>
<app-header
  [fixed]="true"
  [navbarBrandFull]="{src: logo, height: 52, alt: 'sherlock'}"
  [navbarBrandMinimized]="{src: logo, width: 50, height: 25, alt: 'sherlock'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="''">
  <ul class="nav navbar-nav ml-auto">
    <div class="font-weight-bold mr-3 pr-3 border-right">
      <span id="companyName" [ngClass]="{'b-one':_data.facNo=='0'}">{{_data.companyName}}</span>
    </div>
    <div className="d-flex text-right pr-3">
      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline">
      <button type="button" class="btn btn-outline-light" (click)="myModal.show()">
        <i class="fas fa-map-marker-alt"></i>  
        {{_location?.locCode}}</button>
      </span>
    </div>
    <app-notification-center *ngIf="_data.facNo != '0'"></app-notification-center>
    <li class="nav-item dropdown" dropdown placement="bottom right">         
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="true" dropdownToggle (click)="false">
            <span class="avatar avatar-32 bg-primary text-white rounded-circle">{{_data.emp? _data.emp.name.charAt(0) : _data.username.charAt(0)}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <li class="disabled" role="menuitem"><a class="dropdown-item disabled" href="#"><i class="fa fa-user-circle" aria-hidden="true"></i>
          {{_data.emp? _data.emp.name : _data.username}}
        </a>
        </li>
        <li class="disabled" role="menuitem" *ngIf="_data.emp">
          <span class="badge badge-primary mx-5">{{_data.emp.empType}}</span>
          <a class="dropdown-item disabled" href="#"><i class="fa fa-envelope" aria-hidden="true"></i>
          {{_data.emp.email}}
        </a>
        </li>
        <li class="divider dropdown-divider"></li>
        <!-- <li role="menuitem"><a class="dropdown-item" (click)="myModal.show()"><i class='fa fa-map-marker'></i>Location</a></li> -->
        <li role="menuitem"><a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a></li>
      </div>
    </li>
  </ul>
</app-header>
<div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><i class="fas fa-map-marker-alt fa-lg"></i> Change Location</h4>
        <button type="button" class="close" (click)="myModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="settlingsForm" class="form-horizontal">          
          <div class="form-group row mx-3">
            <label class="col-md-3 col-form-label">Location:</label>
            <div class="col-md-9">
              <select class="form-control" formControlName="location" [compareWith]="compareRole" (change)="getLocation($event);">
                <option *ngFor="let _location of _data.locations" value="{{_location._id}}">{{_location.locCode}}</option>              
              </select>
            </div>
            
          </div>        
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="myModal.hide()">Close</button>
        <button type="button"  class="btn btn-primary " (click)="modalSave()">Change</button>
      </div>
    </div>
  </div>
</div>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<app-footer>
  <span class="version">Copyright © 2021 - <a class="text-shlk-blue" style="text-decoration: none;font-weight: bold;" href="https://beyondone.co">
    <img src="/assets/img/brand/beyondone.png" alt="logo" style="padding-bottom: 3px;" height="16">
    Beyondone</a>. All Rights Reserved.</span>
  <span class="ml-auto version"><b>Version.{{version}}</b></span>
</app-footer>
