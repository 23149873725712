import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import _ from 'lodash';

@Injectable()
export class HasMenuPermissionGuardService implements CanActivate {

  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const user: any = JSON.parse(localStorage.getItem('user_data'));
    let userMenu = user.roles.permissions;
    if(!userMenu)
      return false;
    userMenu = userMenu.map(e=> e.module);
    if (user.roles.parent)
      userMenu = userMenu.concat(user.roles.parent.permissions.map(e => e.module));

    if (user != null && user.facNo && user.facNo == '0') {
      return true;
    }
    else if (user != null && user.roles && userMenu.length > 0 && userMenu.includes(route.routeConfig.path)) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
