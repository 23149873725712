// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  version: require('../../package.json').version,
  production: false,
  // apiUrl: "http://localhost:5000/api/v1",
  apiUrl: "http://localhost:5010/api/v1",
  webUrl: "http://localhost:4200",
  // webSocketUrl: 'http://localhost:3000',
  // apiUrl: "https://test.sherlock.beyondone.app/api/v1",
  // webUrl: "https://test.sherlock.beyondone.app",
  // apiUrl: "https://sherlock.beyondone.app/api/api/v1",
  // webUrl: "https://sherlock.beyondone.app",
  webSocketUrl: "http://localhost:5000",
  prefix: "/",
  configs: {
    companyPayTypes: {
      "-1": ["Cash", "Credit", "Visa", "Amex", "Dialog Touch"],
      "5": ["Cash", "Credit", "Visa", "Amex", "Dialog Touch"],
    },    
  },
};
