import { AppNotification } from "./notification-types";

type NCGroupedItem = {
  icon: string;
  background: string;
  link: string;
  count: number;
  title: string;
  message?: string;
  messages?: AppNotification[];
  queryParams?: {};
};

/**
 *  List of notification Channels. This should to be synced with backend channels.
 */
type NotificationChannels =
  | "ORDER_REQUEST"
  | "OR_APPROVAL"
  | "PURCHASE_ORDER"
  | "GOOD_TRANSFER"
  | "EQ_TRANSFER"
  | "VO_REQUEST"
  | "VOR_APPROVAL"
  | "EQ_REQUEST"
  | "EQR_APPROVAL"

/**
 *  - Config used in bell icon list
 *  - Must contain config for all @NotificationChannels
 */
export const NOTIFICATION_CONFIG: {
  [key in NotificationChannels]: NCGroupedItem;
} = {
  ORDER_REQUEST: {
    icon: "cil-library-add",
    background: "bg-success",
    link: "/orapproval",
    title: "Order Request",
    message: "A new order request has created",
    count: 0,
    //queryParams:{status: 'NEW'},
  },
  PURCHASE_ORDER: {
    icon: "cil-boat-alt",
    background: "bg-primary",
    link: "/purchaseorders",
    title: "Purchase Orders",
    message: "A Purchase Order has created",
    count: 0,
    queryParams: { status: "SUBMITTED" },
  },
  GOOD_TRANSFER: {
    icon: "fas fa-refresh",
    background: "btn-shlk-blue",
    link: "/return-transfer",
    title: "Good Transfer",
    message: "A Goods transfer has submitted.",
    count: 0,
    queryParams: { show: "transfers" },
  },
  OR_APPROVAL: {
    icon: "fas fa-clipboard-check",
    background: "bg-info",
    link: "/issue",
    title: "OR Approval",
    message: "A new Order request has approved",
    count: 0,
  },
  EQ_TRANSFER: {
    icon: "fas fa-refresh",
    background: "btn-shlk-blue",
    link: "/equipment-transfers",
    title: "Equipment Transfer",
    message: "An Equipment transfer has submitted.",
    count: 0,
    queryParams: { show: "requests" },
  },

  VO_REQUEST: {
    icon: "fas fa-truck",
    background: "bg-blue",
    link: "/vehicleorderrequest",
    title: "Vehicle Order Request",
    message: "A new vehicle order request has created",
    count: 0,
  },
  VOR_APPROVAL: {
    icon: "fas fa-clipboard-check",
    background: "bg-indigo",
    link: "/vor/approval",
    title: "OR Approval",
    message: "A new Vehicle Order request has approved",
    count: 0,
  },
  EQ_REQUEST: {
    icon: "fas fa-truck",
    background: "bg-cyan",
    link: "/equipments/request",
    title: "Equipment Request",
    message: "A new equipment request has created",
    count: 0,
    //queryParams:{status: 'NEW'},
  },
  EQR_APPROVAL: {
    icon: "fas fa-clipboard-check",
    background: "bg-purple",
    link: "/equipments/request/approval",
    title: "Equipment Request Approval",
    message: "A new Equipment Request has approved",
    count: 0,
  },

};
