import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { AuthService } from './service/auth.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private authSerice: AuthService
    ,private activatedRoute:ActivatedRoute , private titleService:Title) { 
  }

  ngOnInit() {
    let appTitle = this.titleService.getTitle();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      else{
        this.activatedRoute.url.subscribe( (data:Data) =>{
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
             appTitle = child.snapshot.data['title'];
          }
          var currentTitle =this.titleService.getTitle().split(' | ')[0];
          this.titleService.setTitle(`${currentTitle} | ${appTitle}`);
        });
      }
      window.scrollTo(0, 0);
    });
  }
}
