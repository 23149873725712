import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SESSION_TIMEOUT_CHECK_INTERVAL, SESSION_TIMEOUT_SUBJECT } from '../config/const';
import { environment } from '../../environments/environment';
import { User } from '../model/user';
import { Subject, Observable, interval } from 'rxjs';
import { map, filter, withLatestFrom, take } from 'rxjs/operators';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  // private readonly URI = window.location.host.indexOf('localhost') > -1 ? '' : `${environment.apiUrl}`;
  readonly URI = window.location.host.indexOf('localhost') > -1 ? environment.apiUrl: window.location.protocol + '//' + window.location.host + '/api/v1';
  readonly WEB_ROOT = window.location.host.indexOf('localhost') > -1 ? environment.webUrl: window.location.protocol + '//' + window.location.host + '/';
  
  private _timer = interval(SESSION_TIMEOUT_CHECK_INTERVAL);
  private timeoutSubject = new Subject();

  constructor(private httpClient: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService) {
    this.initTokenExpiredEventForHttp401State();
  }

  public authenticate(user: User): Observable<any> {
    const url = `${this.URI}/auth`;
    return this.httpClient.post(url, user, httpOptions);
  }

  /**
   * isAuthenticated
   */
  public isTokenExpired(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      const expired = this.jwtHelper.isTokenExpired(token);
      // console.log(this.jwtHelper.decodeToken(token));
      console.log('expired: ', expired);
      return expired;
    }
    return false;
  }

  public getTimeoutSubject(): Subject<any> {
    return this.timeoutSubject;
  }

  public initTimerForTokenExpired(): void {
    this._timer.pipe(
      map(() => {
         const isAuthenticated = this.isTokenExpired();
        console.log(isAuthenticated);
        return isAuthenticated;
      }),
      filter((expired: boolean) => expired),
      take(1)
    )
    .subscribe(() => {
      // this.showSessionExpiredAlert();
      this.logout();
    });
  }

  private showSessionExpiredAlert(): void {
    alert('YOUR SESSION EXPIRED, PLEASE LOGIN');
  }

  public initTokenExpiredEventForHttp401State(): void {
    SESSION_TIMEOUT_SUBJECT
      .pipe(
        filter(state => state),
        take(1)
      )
      .subscribe(data => {
        console.log('SESSION_TIMEOUT_SUBJECT => 401', data);
        this.showSessionExpiredAlert();
        this.logout();
      });
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    
    window.location.href = this.WEB_ROOT;
  }
}
