import { Component, Input, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { navItems as navItemsList } from '../../_nav';
import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { ModalDirective } from "ngx-bootstrap/modal";
import {LocationService} from '../../service/location.service';
import {Location} from'../../model/location'
// import { userInfo } from 'os';
import { SHERLOCK_APP_VERSION } from '../../config/const';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {
  public version: string = SHERLOCK_APP_VERSION;
  public navItems;
  public sidebarMinimized = true;
 
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public img1: String = environment.prefix + 'assets/img/brand/sherlok-logo1.png';
  public img2: String = environment.prefix + 'assets/img/brand/sherlok-logo.png';

  _data: any;
  @ViewChild("myModal", {}) public myModal: ModalDirective;
  settlingsForm: UntypedFormGroup;
  _locations:Location[]=[];
  _location:Location
  changedLoc:Location;
  location: String = '';

  readonly WEB_URL = window.location.host.indexOf('localhost') > -1 ? environment.webUrl: window.location.protocol + '//' + window.location.host + '/';
  logo: string;

  constructor(private locationService: LocationService) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });

    this.navItems = this.routesByMenuPermission(navItemsList);
    console.log(this.navItems);
    //console.log(this.img1);

    this._data = JSON.parse(localStorage.getItem('user_data'));   
   // this.getLocations(); 
   // this.initForm();
  }
  ngOnInit(){
    this._data = JSON.parse(localStorage.getItem('user_data'));
    this.logo =  this._data.facNo=='0' ? 'assets/img/brand/sherlok-logo.png': environment.prefix + `assets/img/company/${this._data.facNo}.png`;
    this._locations=this._data.locations;
    console.log(this._locations[0]._id+"locations")
    this.getLocations();
    this.initForm();
  }


initForm(): void {
  this.settlingsForm = new UntypedFormGroup({    
    location:new UntypedFormControl(this._location?._id,[])
  })
}

  // getLocations(): void {
  //   this._locations = this._data.locations
  // }

  processRoutes(navList: any[]) {
    const processedRoutes = [];
    const userRoles = JSON.parse(localStorage.getItem('user_data')).roles;

    // Set the processed list if it is null then
    for (const navBar of navList) {
      // TODO : Create type
      // Verify that the role is present
      const roleValid = navBar.roles == null || _.intersection(userRoles, navBar.roles).length > 0;

      if (roleValid) {
        if (navBar.skipLevel === true && navBar.children != null) {
          processedRoutes.push(this.processRoutes(navBar.children));
        } else {
          if (navBar.skipLevel === false || navBar.skipLevel == null) {
            const entry = navBar;
            if (navBar.children != null) {
              const newEntry = {
                ...entry,
                children: (this.processRoutes(navBar.children))
              };
              processedRoutes.push(newEntry);
            } else {
              processedRoutes.push(entry);
            }
          }
        }
      }
    }

    return processedRoutes;
  }

  routesByMenuPermission(navList: any[]) {
    const userdata = JSON.parse(localStorage.getItem('user_data'));

    if (!userdata.roles) return [];
    let userMenu = userdata.roles.permissions;
    if(!userMenu)
      return [];
    userMenu = userMenu.map(e=> e.module); 
    if (userdata.roles.parent)
      userMenu = userMenu.concat(userdata.roles.parent.permissions.map(e => e.module));

    let authMenu = navList;
    userdata.roles && userdata.roles.role == "SUPER ADMIN" || userdata.facNo == "0"
      ? (authMenu = navList)
      : (authMenu = this.filterNavItems(navList,({url}) => userMenu.includes(url.substr(1))));
    
      return authMenu;
  }

  filterNavItems(array:any[],fn:Function) {
    return array.reduce((r, o) => {
      var children = this.filterNavItems(o.children || [], fn);
      if (fn(o) || children.length) r.push(Object.assign({}, o, children.length && { children }));
      return r;
      }, []);
  }

  logout(): void {
    console.log('Logging you out');
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    window.location.href = this.WEB_URL;
  }


  getLocation(event){
    this.location = event.target.value;
    for(let i=0;i<this._locations.length;i++)
    {
      if(this._locations[i]._id==this.location)
      {
        this.changedLoc=this._locations[i];
       
      }

    }
    console.log("location"+ this.location);
    console.log("locationobj"+ this.changedLoc.locCode);
  }

  modalSave(): void{
    const userData = JSON.parse(localStorage.getItem('user_data'));
    console.log('location: ' + JSON.stringify(userData));
    userData.location = this.location;
    userData.locPrefix=this.changedLoc.locPrefix
    localStorage.setItem('user_data', JSON.stringify(userData));

    console.log('location: ' + userData.location);

    this.myModal.hide();
    
    //location.href='#';
    this._data = JSON.parse(localStorage.getItem('user_data'));
    this.getLocations();
    location.reload();
    
  }

  compareRole(val1, val2) {
    return val1 === val2;
  }

 getLocations(){

    this._data.locations.forEach(element => {
      if(element._id==this._data.location){
        this._location=element;
        return;
      }
      
    });
    
    
  }
 
}
