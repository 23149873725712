import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Client } from './client.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Location } from '../model/location';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private client: Client) { }

    getDummyLocation(): Location {
      return {
        _id: '',
        locCode: '',
        locDesc: '',
        locPrefix:'',
        address:'',
        company: '',
//        empType: { _id: '', name: '' }
      };
    }

    addLocation (location: Location): Observable<Location> {
      return <Observable<Location>> this.client.post(`locations`, location);
    }

    updateLocation (location: Location): Observable<Location> {            
      return <Observable<Location>> this.client.put(`locations`, location);     
    }

    deleteLocation(location: Location) {
      return <Observable<Location>> this.client.delete(`locations/${location._id}`);     
    }

     /** GET hero by id. Will 404 if id not found */
    getLocation(name: String): Observable<Location> {
      return <Observable<Location>> this.client.get(`locations/${name}`);
    }

    getLocations(pageNum: number, limit: number): Observable<{locations: any[], count: number, page: number, pages: number }> {      
      const url = (pageNum) ? `locations?page=${pageNum}` : `locations`;
      return <Observable < { locations: any[], count: number, page: number, pages: number }>>this.client.get(url);      
    }
    
    getLocationsByParentId(pageNum: number, limit: number, parentId: String): Observable<{locations: any[], count: number, page: number, pages: number }> {      
      const url = (pageNum) ? `locationsByParentId/${parentId}?page=${pageNum}` : `locations`;
      return <Observable < { locations: any[], count: number, page: number, pages: number }>>this.client.get(url);      
    }
}
