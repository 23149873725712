import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import _ from 'lodash';

@Injectable()
export class IsRolePresentGuardService implements CanActivate {

  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const rolesRequired = route.data.roles;
    const user: any = JSON.parse(localStorage.getItem('user_data'));

    if (user != null && _.intersection(user.roles, rolesRequired).length > 0) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
