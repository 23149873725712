import { environment } from './../../environments/environment';
import { Subject } from 'rxjs';
let map1 = new Map();

export const SESSION_TIMEOUT_CHECK_INTERVAL = 30000;
export const SESSION_TIMEOUT_SUBJECT: Subject<boolean> = new Subject<boolean>();
// should be an odd number
export const PAGE_COUNT = 7;

export const SALE_TYPES = ["Cash", "Credit", "Card1", "Card2", "Card3", "Card4"];
export const COMPANY_TYPES = ["Customer","Supplier","Provider","Finance"];
export const EXP_STATUSES = ["Pending", "Complete"];
export const EXP_PAYMENT_TYPES = ["Cash", "Card", "Other"];
export const OR_STATUS = ["NEW","SUBMITTED","REVIEW","APPROVED","COMPLETED","PARTIALLY ISSUED"];
export const PO_STATUS = ["NEW","SUBMITTED","REVIEW","CANCEL","RECEIVED","PARTIALLY RECEIVED"];
export const PO_TYPES = ["EXTERNAL","INTERNAL"];
export const TRANSFER_STATUSES = ['NEW','TRANSFERRED','ACCEPTED','REJECTED'];
export const OR_ISSUE_STATUSES = ['COMPLETED', 'PARTIALLY ISSUED','APPROVED'];

export const EQR_ASSIGNMENT_STATUSES = ['COMPLETED', 'PARTIALLY COMPLETED','APPROVED'];
export const ASSIGNMENT_STATUS = ["NEW","ASSIGN","RETURN"];



export const MONTHS = new Map([
    [0, 'Jan'],
    [1, 'Feb'],
    [2, 'Mar'],
    [3, 'Apr'],
    [4, 'May'],
    [5, 'June'],
    [6, 'July'],
    [7, 'Aug'],
    [8, 'Sept'],
    [9, 'Oct'],
    [10, 'Nov'],
    [11, 'Dec']
  ])

export const DATE_FORMAT = 'DD/MM/YYYY';
export const BS_DATE_PICKER_CONFIG = {
  dateInputFormat: DATE_FORMAT,
  showWeekNumbers: false,
  useUtc: false,
  selectFromOtherMonth: true,
};

export const SHERLOCK_APP_VERSION = environment.version;