import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of ,  Observable, throwError } from 'rxjs';
import { SESSION_TIMEOUT_SUBJECT } from '../config/const';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Client {

  // readonly API_ROOT = environment.apiUrl;
  readonly API_ROOT = window.location.host.indexOf('localhost') > -1 ? environment.apiUrl: window.location.protocol + '//' + window.location.host + '/api/v1';
  
  defaultOptions;

  constructor(private httpClient: HttpClient) {}

  private getDefaultOptions(){
   return this.defaultOptions = {
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`,
        data: `${localStorage.getItem('user_data')}`
      },
    };

  }

  private handleError(errResponse) {
    if (errResponse.status === 401) {
      console.log('TOKEN EXPIRED');
      SESSION_TIMEOUT_SUBJECT.next(true);
      // TODO: invoke session timeout function
    }
    return throwError(errResponse || Error('API ERROR'));
  }

  public get(route, options = {}): Observable<any> {    
    // console.log('host: ' + window.location.host + ' ' + window.location.host.indexOf('localhost'));
    return this.httpClient.get(`${this.API_ROOT}/${route}`, {
      ...options,
      ...this.getDefaultOptions()
    }).pipe(
      catchError(this.handleError)
    );
  }

  public post(route, data, options = {}) {
    // console.log('host: ' + window.location.host);
    return this.httpClient.post(`${this.API_ROOT}/${route}`, data, {
      ...options,
      ...this.getDefaultOptions()
    }).pipe(
      catchError(this.handleError)
    );
  }

  public put(route, data, options = {}) {
    return this.httpClient.put(`${this.API_ROOT}/${route}`, data, {
      ...options,
      ...this.getDefaultOptions()
    }).pipe(
      catchError(this.handleError)
    );
  }

  public delete(_id, options = {}) {
    return this.httpClient.delete(`${this.API_ROOT}/${_id}`, {
      ...options,
      ...this.getDefaultOptions()
    }).pipe(
      catchError(this.handleError)
    );
  }

  public getUserData() {
    return JSON.parse(localStorage.getItem('user_data'));  
  }

  public getLoggedInUserName() {
    const { username } = this.getUserData();
    return username;  
  }
}
