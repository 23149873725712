<div class="mx-2 px-1 mt-2" >
  <i class="pi pi-bell p-mr-4 p-text-secondary bell" pBadge severity="danger" style="font-size: 1.5rem;color:#fff;"
    [value]='totalCount' (click)="op.toggle($event)"></i>
</div>

<p-overlayPanel [baseZIndex]="1050" #op>
  <ng-template pTemplate>
    <div class="card mb-0" style="width: 396px;border: none;">
      <div class="card-header pt-3">
        Notifications
      </div>
      <div class="card-body p-0">
          
        <div class="list list-row" *ngFor="let item of getGroupedN();trackBy:trackByFn">
          <div class="list-item" data-id="13" role="option" aria-grabbed="false">
            <div><a (click)="groupedItemAction(item)">
                <span class="avatar avatar-32 w-40 text-white rounded-circle" [ngClass]="item?.background" >
                    <span class="p-1"><i [class]="item.icon" pBadge severity="danger" [value]="item?.count"></i></span>
                </span></a>
            </div>
            <div class="d-flex pb-2 col">
              <a (click)="groupedItemAction(item)" ><div class="flex" >
                <div class="item-author text-muted font-weight-bolder">{{item.title}}</div>
                <div class="item-except text-muted text-sm h-1x" *ngIf="item.message">{{item.message}}</div>
              </div></a>
              <div class="no-wrap my-auto ml-auto">
                <div class="btn-group" dropdown placement="bottom left">
                    <button pButton pRipple type="button" icon="fa fa-ellipsis-h" class="p-button-rounded p-button-text" 
                    dropdownToggle></button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                        role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="itemAction(item)">Open</a></li>
                      <li role="menuitem"><a class="dropdown-item" (click)="clearGroup(item.channel)" >Clear</a></li>
                    </ul>
                  </div>
                <!-- <div class="item-date text-muted text-sm d-none d-md-block">3 min. ago</div> -->
              </div>
            </div>
          </div>
        </div> <!-- END OF LIST ITEM -->

        <div class="alert alert-info my-2" role="alert" *ngIf="getGroupedN().length == 0">
          <p class="m-0">You are all caught up 😊 </p>
        </div>
      </div>
      <div class="card-footer">
    <!--    <button class="text-sm btn" (click)="markAllRead()" *ngIf="getGroupedN().length > 0">
         <i class="fa fa-check" aria-hidden="true" ></i> Mark all as Read</button> -->
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
